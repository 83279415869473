<template>
  <div class="arContent">
    <div class="topBox flex" v-if="isEdit">
      <div class="top-left">
        <app-btn
          :type="activeIndex==index ? 'active' : ''"
          class="mr-20"
          v-for="(item,index) in headerSelect"
          :key="index"
          @click="switchIndex(index)">
            {{item.name}}
          </app-btn>
      </div>
      <!-- <div class="top-right flex">
        <app-search
          v-model="searchInput"
          placeholder="请输入AR资源名称"
        ></app-search>
        <app-btn>更多</app-btn>
      </div> -->
    </div>
    <a-row :gutter="20" v-if="!isEdit">
      <a-col
        class="mt-20"
        :span="5"
        v-for="(item, index) in arList"
        :key="item.id"
      >
        <div class="img-box">
          <a-image :src="OSSURL + '/' + item.icon_path" />
        </div>
        <p class="mt-5">{{item.name}}</p>
      </a-col>
    </a-row>
    <!-- 编辑 -->
    <a-row :gutter="20" v-if="isEdit">
      <a-col
        class="mb-20"
        :span="5"
        v-for="(item, index) in arList"
        :key="item.id"
      >
        <listItem style="cursor: pointer;" :itemData="item" @click="getSelectData(item)">
          <div class="imgBox">
            <img class="img" :src="OSSURL+item.icon_path" alt=""/>
          </div>
        </listItem>
      </a-col>
    </a-row>
    <!-- <ant-page class="txt-r" :pagination="pagination"></ant-page> -->
  </div>
</template>
<script>
import { computed, defineComponent, onMounted, reactive, ref,watch,inject} from 'vue'
import AppSearch from '@/common/components/search'
import listItem from '@/common/components/listItem'
import antPage from '@/common/components/page'
import { OSSURL } from '@/common/hooks/oss-config.js'
import { fetchEncyclopedias } from '@/api/subject/index.js'
export default defineComponent({
  props:{
    data: {
      type: Array,
    },
    isEdit: {
      type: Boolean,
      default: false,
    }, // 是否可编辑
    course_id:{}
  },
  components:{AppSearch,listItem,antPage},
  setup(props){
    const headerSelect = reactive([{
      id:1,
      name:'推荐',
    },{
      id:2,
      name:'已选',
    },{
      id:3,
      name:'未选',
    }])
     //去重
    const unique = (arr) => {
      const _ids = arr.map(item=>item.id)
      return arr.filter((item,index)=>{
        return _ids.indexOf(item.id,0) === index;
      })
    }
    const activeIndex = ref(0)
    const arList = ref([]) //绑定数据
    const commandList = ref([]) //总AR数据
    const commendArList = ref([]) //推荐的百科
    //获取推荐的百科
    const getRecommendEnc = () => {
      fetchEncyclopedias({
        course_id:parseInt(props.course_id)
      }).then(res=>{
        const _arr = props.data.map(item=>{
          item.isCheck = true
          item.isRecommend = false
          return item
        })
        const _ids = _arr.map(item=>item.id)
        commendArList.value = res.data.map(item => {
          item.isRecommend = true
          return item
        })
        commandList.value = unique(commendArList.value.concat(_arr)).map((item)=>{
          if(_ids.indexOf(item.id)>=0){
            item.isCheck = true
          } else {
            item.isCheck = false
          }
          return item
        })
        getLists(activeIndex.value)
      })
    }
    const getLists = (index) => {
      console.log(index)
      switch(index){
        case 0:
          arList.value = commandList.value.filter(item=>item.isRecommend==true)
          break;
        case 1:
          arList.value = commandList.value.filter(item=>item.isCheck==true)
          break;
        case 2:
          arList.value = commandList.value.filter(item=>item.isCheck==false)
          break;
      }
    }
    const switchIndex = (index) => {
      activeIndex.value = index
      getLists(index)
    }
    const pagination = reactive({
      pageSize:20,
      total:arList.length,
    })
    const updateCommonList = inject('updateCommonList')
    const getSelectData = (item) => {
      item.isCheck = !item.isCheck
      updateCommonList(commandList.value.filter(item=>item.isCheck),true)
    }

    watch(()=>props.data,()=>{
      if(props.isEdit){
        getRecommendEnc()
      } else {
        arList.value = props.data
      }
    },{immediate: true})
    return {
      getSelectData,
      // selectArList,
      switchIndex,
      activeIndex,
      headerSelect,
      pagination,
      OSSURL,
      searchInput:'',
      arList
    }
  }
})
</script>
<style lang="scss" scoped>
.arContent {
  padding: 10px 10px;
  .topBox {
    justify-content: space-between;
    margin-bottom: 17px;
  }
  .searchBox {
    align-items: center;
    padding: 0 10px;
    background: #ffffff;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
  }
}
.mb-20 {
  margin-bottom: 20px;
}
.ant-col-5 {
  display: block;
  flex: 0 0 20%;
  max-width: 20%;
}
.img-box{
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  p{
    color: #363535;
    line-height: 20px;
  }
}
</style>