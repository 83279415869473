import request from '@/libs/request.js'
import subjectApi from './subject.api.js'

// 获取导航栏
export function fetchSubjectMenu(params) {
  return request({
    url: subjectApi.subjectNav,
    method: 'get',
    params
  })
}

// 获取课程详情
export function fetchCourseWareDes(params) {
  return request({
    url: subjectApi.courseWareDes,
    method: 'get',
    params
  })
}

// 获取课程详情
export function fetchCourseDetail(params) {
  return request({
    url: subjectApi.getCourseDetail,
    method: 'get',
    params
  })
}

// 收藏课程
export function collectCourse(data) {
  return request({
    url: subjectApi.collectCourse,
    method: 'post',
    data
  })
}

// 分享课程
export function fetchShareCourse(data) {
  return request({
    url: subjectApi.shareCourse,
    method: 'post',
    data
  })
}

// 创建课程
export function createCourse(data) {
  return request({
    url: subjectApi.createCourse,
    method: 'post',
    data
  })
}

// 创建课程
export function fetchCreateCourse(data) {
  return request({
    url: subjectApi.createCourse,
    method: 'post',
    data
  })
}

// 编辑课程
export function handleEditCourse(data) {
  return request({
    url: subjectApi.createCourse,
    method: 'put',
    data
  })
}

// 上传备课资源
export function uploadResourse(data) {
  return request({
    url: subjectApi.uploadResourse,
    method: 'post',
    data
  })
}

// 收藏课程
export function fetchCollectCourse(data) {
  return request({
    url: subjectApi.collectCourse,
    method: 'post',
    data
  })
}

// 获取推荐百科
export function fetchEncyclopedias(params) {
  return request({
    url: subjectApi.encyclopedias,
    method: 'get',
    params
  })
}

// 删除课程
export function fetchDeleteCourse(data) {
  return request({
    url: subjectApi.deleteCourse,
    method: 'delete',
    data
  })
}

// 获取资源库
export function fetchSourse(params) {
  return request({
    url: subjectApi.sourse,
    method: 'get',
    params
  })
}

// 上传备课资源
export function fetchUploadResourse(data) {
  return request({
    url: subjectApi.uploadResourse,
    method: 'post',
    data
  })
}

// 编辑课程
export function fetchEditCourse(data) {
  return request({
    url: subjectApi.createCourse,
    method: 'put',
    data
  })
}

// 获取被分享课程详情列表
export function fetchShareCourseDetail(params) {
  return request({
    url: subjectApi.shareCourseDetail,
    method: 'get',
    params
  })
}

//获取资源
export function fetchGetResources(params) {
  return request({
    url: subjectApi.getResources,
    method: 'get',
    params
  })
}

// 获取标签列表
export function fetchTagList(params) {
  return request({
    url: subjectApi.tagList,
    method: 'get',
    params
  })
}

// 删除资源
export function fetchDeleteResourse(data) {
  return request({
    url: subjectApi.getResources,
    method: 'delete',
    data
  })
}

// 获取标签列表
export function fetchMoreWiki(params) {
  return request({
    url: subjectApi.moreWiki,
    method: 'get',
    params
  })
}

// 批量删除资源
export function fetchBatchDeleteResourse(data) {
  return request({
    url: subjectApi.resourcesBatch,
    method: 'delete',
    data
  })
}

// 编辑资源
export function handleEditResourse(data) {
  return request({
    url: subjectApi.getResources,
    method: 'put',
    data
  })
}
//保存模型资源
export function fetchFbx(data) {
  return request({
    url: subjectApi.fbx,
    method: 'post',
    data
  })
}

// 获取模型列表
export function fetchFbxList(params) {
  return request({
    url: subjectApi.fbxList,
    method: 'get',
    params
  })
}

// 模型包列表, 展开二级所属模型
export function fetchFbxFiles(params) {
  return request({
    url: subjectApi.fbxFiles,
    method: 'get',
    params
  })
}

//重命名
export function handleEditFileName(data) {
  return request({
    url: subjectApi.fileRen,
    method: 'put',
    data
  })
}

// 以下为教研部分
// 获取上传时阿里云临时签名
export function getResearchOssToken(params) {
  return request({
    url: subjectApi.getOssToken,
    method: 'get',
    params: Object.assign({
      client: 'arseek.web'
    }, params),
  })
}
// 获取个人基础信息
export function getPersonInfo(params) {
  return request({
    url: subjectApi.getPersonInfo,
    method: 'get',
    params,
  })
}
// 修改个人基础信息
export function changePersonInfo(data) {
  return request({
    url: subjectApi.getPersonInfo,
    method: 'put',
    data,
  })
}
// 通过原密码修改密码
export function changePwd(data) {
  return request({
    url: subjectApi.changePwd,
    method: 'put',
    data,
  })
}
// 发送验证码
export function sendCode(data) {
  return request({
    url: subjectApi.sendCode,
    method: 'put',
    data,
  })
}
// 修改用户绑定手机
export function changePhone(data) {
  return request({
    url: subjectApi.changePhone,
    method: 'put',
    data,
  })
}

// 我的小组
export function getMyGroup(params) {
  return request({
    url: subjectApi.getMyGroup,
    method: 'get',
    params,
  })
}
// 创建小组
export function createGroup(data) {
  return request({
    url: subjectApi.createGroup,
    method: 'post',
    data,
  })
}
// 创建小组
export function editGroup(data) {
  return request({
    url: subjectApi.createGroup,
    method: 'put',
    data,
  })
}
// 平台小组
export function platformGroup(params) {
  return request({
    url: subjectApi.platformGroup,
    method: 'get',
    params,
  })
}
//粉丝，关注
export function getFans(params) {
  return request({
    url: subjectApi.getFans,
    method: 'get',
    params,
  })
}
// 动态主页列表
export function getShareList(params) {
  return request({
    url: subjectApi.getShareList,
    method: 'get',
    params,
  })
}

// 查询指定小组享动态列表
export function getShareTeamList(params) {
  return request({
    url: subjectApi.getShareTeamList,
    method: 'get',
    params,
  })
}

// 评论他人分享
export function sendComment(data) {
  return request({
    url: subjectApi.sendComment,
    method: 'post',
    data,
  })
}

// 点赞分享
export function likeShare(data) {
  return request({
    url: subjectApi.likeShare,
    method: 'post',
    data,
  })
}

// 动态评论列表
export function getComment(params) {
  return request({
    url: subjectApi.getComment,
    method: 'get',
    params,
  })
}
// 设置关注，取消
export function setFans(params) {
  return request({
    url: subjectApi.setFans,
    method: 'get',
    params,
  })
}
// 删除自己评论内容
export function deleteComment(data) {
  return request({
    url: subjectApi.deleteComment,
    method: 'delete',
    data,
  })
}

// 自己删除分享动态
export function deleteShare(data) {
  return request({
    url: subjectApi.deleteShare,
    method: 'delete',
    data,
  })
}

//切换卡：1 动态 2资源 3成员
export function getMember(params) {
  return request({
    url: subjectApi.getMember,
    method: 'get',
    params,
  })
}
// 小组详情
export function getGroupDes(params) {
  return request({
    url: subjectApi.getGroupDes,
    method: 'get',
    params,
  })
}
// 申请加入、退出小组
export function joinGroup(data) {
  return request({
    url: subjectApi.joinGroup,
    method: 'put',
    data,
  })
}

// 解散小组
export function deleteGroup(data) {
  return request({
    url: subjectApi.delGroupMember,
    method: 'put',
    data,
  })
}


// 删除成员
export function deleteGroupUser(data) {
  return request({
    url: subjectApi.delGroupMember,
    method: 'put',
    data,
  })
}

// 获取ta人基础信息
export function getOtherInfo(params) {
  return request({
    url: subjectApi.getOtherInfo,
    method: 'get',
    params,
  })
}
// 发布分享
export function sendShare(data) {
  return request({
    url: subjectApi.sendShare,
    method: 'post',
    data,
  })
}

// 设置动态小组可见-下拉
export function setTeamsVisible(params) {
  return request({
    url: subjectApi.setTeamsVisible,
    method: 'get',
    params,
  })
}
// 获取ta人动态信息
export function getOtherShare(params) {
  return request({
    url: subjectApi.getOtherShare,
    method: 'get',
    params,
  })
}
// 获取推荐关注用户
export function getHotAccounts(params) {
  return request({
    url: subjectApi.getHotAccounts,
    method: 'get',
    params,
  })
}
// 获取教研资源类型
export function getSourseType(params) {
  return request({
    url: subjectApi.getSourseType,
    method: 'get',
    params,
  })
}
// 获取教研资源
export function getSourse(params) {
  return request({
    url: subjectApi.getSourse,
    method: 'get',
    params,
  })
}

// 组长删除组员动态
export function lenderDel(data) {
  return request({
    url: subjectApi.lenderDel,
    method: 'delete',
    data,
  })
}
// 修改头像
export function changeHeaderImg(data) {
  return request({
    url: subjectApi.changeHeaderImg,
    method: 'put',
    data,
  })
}

// 获取资源详情
export function sourseDetail(params) {
  return request({
    url: subjectApi.sourseDetail,
    method: 'get',
    params,
    hidden: true
  })
}
// 发布资源
export function postSourse(data) {
  return request({
    url: subjectApi.getSourse,
    method: 'post',
    data,
  })
}
// 编辑资源
export function editSourse(data) {
  return request({
    url: subjectApi.getSourse,
    method: 'put',
    data,
  })
}
// 删除资源
export function delSourse(data) {
  return request({
    url: subjectApi.getSourse,
    method: 'delete',
    data,
  })
}

//打包
export function uploadPack(data) {
  return request({
    url: subjectApi.uploadPack,
    method: 'post',
    data,
    hidden: true,
  })
}

//ta的资源
export function getTAsourse(params) {
  return request({
    url: subjectApi.getTAsourse,
    method: 'get',
    params,
  })
}

//创建活动
export function addActivity(data) {
  return request({
    url: subjectApi.addActivity,
    method: 'post',
    data,
  })
}

//获取小组的研活动
export function groupActivity(params) {
  return request({
    url: subjectApi.groupActivity,
    method: 'get',
    params,
  })
}

// 活动详情
export function getActivityDesc(params) {
  return request({
    url: subjectApi.getActivityDesc,
    method: 'get',
    params,
    hidden: true
  })
}

// 编辑活动
export function editActivityDesc(method, data) {
  return request({
    url: subjectApi.addActivity,
    method,
    data,
  })
}

//个人中心我的活动与她的活动
export function myAndTaActivity(params) {
  return request({
    url: subjectApi.myAndTaActivity,
    method: 'get',
    params,
  })
}

//研活动-列表首页
export function homeActivityList(params) {
  return request({
    url: subjectApi.homeActivityList,
    method: 'get',
    params,
  })
}


// 添加活动环节
export function addActivityLink(data, method = 'post') {
  return request({
    url: subjectApi.addActivityLink,
    method,
    data,
  })
}

// 获取活动成员
export function getActivityMember(params) {
  return request({
    url: subjectApi.getActivityMember,
    method: 'get',
    params,
  })
}

// 删除活动环节
export function deleteActivityItem(data) {
  return request({
    url: subjectApi.deleteActivityItem,
    method: 'put',
    data,
  })
}

// 删除活动
export function deleteActivity(data) {
  debugger
  return request({
    url: subjectApi.deleteActivity,
    method: 'delete',
    data,
  })
}

// 编辑研活动附件（活动成果、现场图）
export function editActivityAttachment(data) {
  return request({
    url: subjectApi.editActivityAttachment,
    method: 'put',
    data,
  })
}

// 删除活动成员 
export function deleteRember(data) {
  return request({
    url: subjectApi.getActivityMember,
    method: 'delete',
    data,
  })
}
//加入和推出活动
export function addActivityMember(data) {
  return request({
    url: subjectApi.addActivityMember,
    method: 'put',
    data,
  })
}

// 活动环节详情
export function linkDetails(params) {
  return request({
    url: subjectApi.linkDetails,
    method: 'get',
    params,
    hidden: true
  })
}

// 发表讨论
export function sendDiscuss(data) {
  return request({
    url: subjectApi.sendDiscuss,
    method: 'post',
    data,
  })
}

// 获取讨论列表
export function getDiscuss(params) {
  return request({
    url: subjectApi.sendDiscuss,
    method: 'get',
    params,
  })
}

// 删除讨论列表
export function deldteDiscuss(data) {
  return request({
    url: subjectApi.deleteDiscuss,
    method: 'put',
    data,
  })
}

// 编辑活动环节
export function editLink(data) {
  return request({
    url: subjectApi.editLink,
    method: 'put',
    data,
  })
}

//统计处，查看人员完成情况
export function getStatistics(params) {
  return request({
    url: subjectApi.getStatistics,
    method: 'get',
    params,
  })
}


// 发起直播
export function launchLive(data) {
  return request({
    url: subjectApi.launchLive,
    method: 'post',
    data,
  })
}

// 获取直播信息
export function getLaunchLive(params) {
  return request({
    url: subjectApi.getLaunchLive,
    method: 'get',
    params,
    hidden: true,
    load: true
  })
}


// 下载与查看回调
export function linkPlay(params) {
  return request({
    url: subjectApi.linkPlay,
    method: 'get',
    params,
    hidden: true,
    load: true
  })
}

// 直播视频统计
export function liveStatistics(params) {
  return request({
    url: subjectApi.liveStatistics,
    method: 'get',
    params
  })
}

// 设置直播统计
export function setLiveTime(data) {
  return request({
    url: subjectApi.liveStatistics,
    method: 'post',
    data,
    hidden: true,
    load: true
  })
}

// 回放视频签名
export function getPsign(params) {
  return request({
    url: subjectApi.getPsign,
    method: 'get',
    params
  })
}

// 获取小组邀请码
export function getinvitecode(params) {
  return request({
    url: subjectApi.getinvitecode,
    method: 'get',
    params
  })
}

// 邀请码加入小组
export function codeMember(data) {
  return request({
    url: subjectApi.codeMember,
    method: 'post',
    data,
  })
}

// 获取我的ar资源
export function getMyEnc(params) {
  return request({
    url: subjectApi.myEnc,
    method: 'get',
    params
  })
}

// 创建ar资源
export function addArSourse(data) {
  return request({
    url: subjectApi.arEnc,
    method: 'post',
    data
  })
}

// 编辑AR资源
export function editArSourse(data) {
  return request({
    url: subjectApi.arEnc,
    method: 'put',
    data,
  })
}

// 获取知识点
export function getKnowledgepoint(params) {
  return request({
    url: subjectApi.knowledgepoint,
    method: 'get',
    params
  })
}

// 创建知识点
export function addKnowledgepoint(data) {
  return request({
    url: subjectApi.knowledgepoint,
    method: 'post',
    data
  })
}

// 编辑知识点
export function editKnowledgepoint(data) {
  return request({
    url: subjectApi.knowledgepoint,
    method: 'put',
    data,
  })
}

// 删除知识点
export function deleteKnowledgepoint(data) {
  return request({
    url: subjectApi.knowledgepoint,
    method: 'delete',
    data,
  })
}

// 知识点排序
export function knowledgepointSort(data) {
  return request({
    url: subjectApi.sortKnowledgepoint,
    method: 'put',
    data,
  })
}

// 获取百科类型
export function getCategory(params) {
  return request({
    url: subjectApi.category,
    method: 'get',
    params
  })
}

export function deleteARSourse(data) {
  return request({
    url: subjectApi.arEnc,
    method: 'delete',
    data,
  })
}

// 资源详情
export function getARSourseDetail(params) {
  return request({
    url: subjectApi.arEnc,
    method: 'get',
    params
  })
}

// AR资源处选模型列表
export function getMyFbxFile(params) {
  return request({
    url: subjectApi.myFbxFile,
    method: 'get',
    params
  })
}

// 我的ar-课程内
export function fetchGetMyCourseAr(params) {
  return request({
    url: subjectApi.myCourseAr,
    method: 'get',
    params
  })
}