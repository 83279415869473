export const OSSURL = 'https://edu.oss.arseek.cn/'

export const SOURSE_TYPE = {
  ppt: 11,
  word: 12,
  excel: 13,
  pdf: 14,
  图片: 21,
  音频: 31,
  视频: 32,
  fbx: 40,
}

export const OSS_ICON = {
  32: {
    url: require('@/assets/images/icon_file_mp4.png'),
  },
  31: {
    url: require('@/assets/images/icon_audio_max.png'),
  },
  12: {
    url: require('@/assets/images/icon_doc.png'),
  },
  11: {
    url: require('@/assets/images/icon_ppt.png'),
  },
  13: {
    url: require('@/assets/images/icon_xls.png'),
  },
  14: {
    url: require('@/assets/images/pdf.png'),
  },
  21: {
    url: require('@/assets/images/icon_img.png'),
  },
  40: {
    url: require('@/assets/images/fbx.png'),
  },
  m4: {
    url: require('@/assets/images/icon_file_mp4.png'),
  },
  m3: {
    url: require('@/assets/images/icon_audio_max.png'),
  },
  w1: {
    url: require('@/assets/images/icon_doc.png'),
  },
  w2: {
    url: require('@/assets/images/icon_ppt.png'),
  },
  w3: {
    url: require('@/assets/images/icon_xls.png'),
  },
  w4: {
    url: require('@/assets/images/pdf.png'),
  },
  p1: {
    url: require('@/assets/images/icon_img.png'),
  },
}

export const FILES_TYPE = [
  {
    type: '12',
    value: '.doc.docx',
  },
  {
    type: '11',
    value: '.ppt.pptx',
  },
  {
    type: '13',
    value: '.xls.xlsx',
  },
  {
    type: '14',
    value: '.pdf.fbx',
  },
  {
    type: '21',
    value: '.png.jpg',
  },
  {
    type: '31',
    value: '.mp3',
  },
  {
    type: '32',
    value: '.mp4',
  },
  {
    type: '1',
    value: '.abw',
  },
  {
    type: '2',
    value: '.aba',
  },
  {
    type: '3',
    value: '.abi',
  },
  {
    type: '40',
    value: '.zip',
  },
]
export const SEND_TYPE = {
  1: {
    lable: '图片',
    filter: '.png.jpg',
    bunket: 'image', // 必传
    maxNum: 8,
    size: 5,
    type: 'p1',
    isRealName: true, // 是否重命名
  },
  2: {
    lable: '音频',
    filter: '.mp3',
    bunket: 'audio',
    maxNum: 5,
    size: 50,
    type: 'm3',
    isRealName: true,
  },
  3: {
    lable: '视频',
    filter: '.mp4',
    bunket: 'video',
    maxNum: 5,
    size: 100,
    type: 'm4',
    isRealName: true,
  },
  4: {
    lable: '文档',
    filter: '.pdf.doc.docx.xls.xlsx',
    bunket: 'wps',
    maxNum: 5,
    size: 10,
    type: 'w',
    isRealName: true,
  },
  5: {
    lable: 'abw',
    filter: '.abw',
    bunket: 'ab',
    maxNum: 5,
    size: 10,
    type: 'abw',
    isRealName: true,
  },
  6: {
    lable: 'aba',
    filter: '.aba',
    bunket: 'ab',
    maxNum: 5,
    size: 10,
    type: 'aba',
    isRealName: true,
  },
  7: {
    lable: 'abi',
    filter: '.abi',
    bunket: 'ab',
    maxNum: 5,
    size: 10,
    type: 'abi',
    isRealName: true,
  },
  8: {
    lable: 'PPT',
    filter: '.ppt.pptx',
    bunket: 'ppt',
    maxNum: 5,
    size: 50,
    type: 'w2',
    isRealName: true,
  },
}

export const SEND_TYPE_new = {
  1: {
    lable: '图片',
    filter: '.png.jpg',
    bunket: 'image', // 必传
    maxNum: 20,
    size: 10,
    type: 'p1',
    isRealName: true, // 是否重命名
  },
  2: {
    lable: '音频',
    filter: '.mp3',
    bunket: 'audio',
    maxNum: 20,
    size: 1024,
    type: 'm3',
    isRealName: true,
  },
  3: {
    lable: '视频',
    filter: '.mp4',
    bunket: 'video',
    maxNum: 20,
    size: 1024,
    type: 'm4',
    isRealName: true,
  },
  4: {
    lable: '文档',
    filter: '.pdf.doc.docx.xls.xlsx',
    bunket: 'wps',
    maxNum: 20,
    size: 20,
    type: 'w',
    isRealName: true,
  },
  5: {
    lable: 'abw',
    filter: '.abw',
    bunket: 'ab',
    maxNum: 20,
    size: 20,
    type: 'abw',
    isRealName: true,
  },
  6: {
    lable: 'aba',
    filter: '.aba',
    bunket: 'ab',
    maxNum: 20,
    size: 20,
    type: 'aba',
    isRealName: true,
  },
  7: {
    lable: 'abi',
    filter: '.abi',
    bunket: 'ab',
    maxNum: 20,
    size: 20,
    type: 'abi',
    isRealName: true,
  },
  8: {
    lable: 'PPT',
    filter: '.ppt.pptx',
    bunket: 'ppt',
    maxNum: 1,
    size: 300,
    type: 'w2',
    isRealName: true,
  },
  9: {
    lable: '文档',
    filter: '.pdf.doc.docx.xls.xlsx.ppt.pptx',
    bunket: 'wps',
    maxNum: 20,
    size: 10,
    type: 'w',
    isRealName: true,
  },
  10: {
    lable: 'fbx',
    filter: '.zip',
    bunket: 'fbx',
    maxNum: 20,
    size: 100,
    type: 'fbx',
    isRealName: true,
  },
}
