export default {
  subjectNav: 'api/edu/tree', // 获取学科目录
  courseWareDes: 'api/edu/plan/course/v4', // 获取课程详情
  getCourseDetail: 'api/edu/plan/course/v4', //获取课程详情[post]
  collectCourse: 'api/edu/collect/course', //收藏课程[post]
  shareCourse: 'api/edu/plan/share/course', //分享课程[post]
  createCourse: 'api/edu/plan/course/v4', //创建课程[post] 编辑课程[put]
  encyclopedias: 'api/edu/recommend/encyclopedias', //推荐百科[get]
  deleteCourse: 'api/edu/plan/delete/course', // 删除课程
  uploadResourse: 'api/edu/personal/resource/v4', // 上传备课资源[post]
  sourse: 'api/edu/personal/resource/v4', // 资源库
  shareCourseDetail:'api/edu/plan/share/course/v4', // 被分享课程详情列表
  getResources:'api/edu/resources',//获取资源 删除资源 修改资源
  shareCourseDetail: 'api/edu/plan/share/course/v4', // 被分享课程详情列表
  tagList: 'api/edu/tags', // 获取tag列表
  moreWiki: 'api/edu/more/encyclopedias', // 获取更多百科
  resourcesBatch: 'api/edu/resources/batch', //批量删除
  fbx: 'api/course/fbx',
  fbxList: 'api/course/fbx/list', // 模型列表
  fbxFiles: 'api/course/fbx/files', // 模型包列表, 展开二级所属模型
  fileRen: 'api/course/file/ren', // 模型重命名

  // 教研
  getOssToken: `/api/upload/teach/getststoken`, // 获取上传时阿里云临时签名
  getPersonInfo: `/api/user/teach/account/info`, // 获取个人基础信息
  changePwd: `/api/user/password`, // 通过原密码修改密码
  changePhone: `/api/user/mobile`, // 修改用户绑定手机
  sendCode: `/api/sms/sendsms/code`, // 发送验证码
  getMyGroup: `/api/user/my/teams`, // 我的小组
  createGroup: `/api/user/teach/team`, // 创建小组 
  platformGroup:`/api/user/teams/page`,// 平台小组
  getFans:`/api/user/my/fans`,// 粉丝,关注
  getShareList: `/api/bbs/share/list`, // 动态主页列表
  getShareTeamList: `/api/bbs/share/list/team`, // 查询指定小组享动态列表
  sendComment: `/api/bbs/comment`, // 评论他人分享
  getComment: `/api/bbs/comment/list`, // 动态评论列表
  likeShare: `/api/bbs/like`, // 点赞分享
  setFans:`/api/user/set/fans`,// 
  deleteComment: `/api/bbs/comment`, // 删除自己评论内容
  deleteShare: `/api/bbs/share`, // 自己删除分享动态
  getMember:`api/user/teach/team/details/list`,// 切换卡：1 动态 2资源 3成员
  getGroupDes: `/api/user/teach/team/detail`, // 小组详情
  joinGroup: `/api/user/teach/team/member`, // 申请加入、退出小组
  getOtherInfo:`/api/user/teach/user/info`,// 获取他人基本信息
  sendShare: `/api/bbs/share`, // 发布分享
  setTeamsVisible: `/api/user/visible/teams`, // 设置动态小组可见-下拉
  getOtherShare:`/api/bbs/share/list/other`,// 查询其他人分享动态列表
  getHotAccounts:`/api/user/teach/hot/accounts`,// 推荐关注用户
  getSourseType:`/api/bbs/teach/category`,// 教研资源类型
  getHotAccounts:`api/user/teach/hot/accounts`,// 推荐关注用户
  lenderDel:`api/bbs/share/team/admin`,// 组长删除组员动态
  changeHeaderImg:`api/user/avatar`,// 修改头像
  getSourse:`api/bbs/teach/resource`,// 获取资源 发布资源 编辑资源 删除资源
  sourseDetail:`api/bbs/teach/resource/detail`,// 资源详情
  // postSourse:`api/bbs/teach/resource`,// 发布资源
  uploadPack:`api/upload/teach/async/pack`,// 打包
  getTAsourse:`api/bbs/teach/resource/user`,// 获取ta的资源
  addActivity:`api/bbs/activity`,// 创建活动
  groupActivity:`api/bbs/team/activity`,// 小组的研活动
  getActivityDesc:`api/bbs/activity/details`,// 活动详情
  myAndTaActivity:`api/bbs/user/activity`,// 个人中心-我的，Ta的活动
  homeActivityList:`api/bbs/activity`,// 研活动首页活动列表
  addActivityLink: `api/bbs/activity/link`,// 添加活动环节
  getActivityMember: `api/bbs/activity/member`,// 活动成员列表
  deleteActivityItem: `api/bbs/delete/activity`,// 删除活动环节
  deleteActivity: `api/bbs/activity`,// 删除活动
  addActivityMember:`api/bbs/activity/member`,// 加入和推出活动
  linkDetails:`/api/bbs/link/details`,// 各环节详情
  editActivityAttachment: `api/bbs/activity/attachment`,// 编辑研活动附件（活动成果、现场图）
  sendDiscuss: `api/bbs/link/discuss`,// 发表讨论
  deleteDiscuss: `api/bbs/activity/discuss`,// 删除讨论
  editLink:`/api/bbs/activity/link`,// 编辑活动环节
  getStatistics:`api/bbs/link/statistics`,// 统计处，查看人员完成情况
  getLaunchLive:`api/bbs/live/class`,// 获取直播信息
  launchLive:`api/bbs/liveclass`,// 发起直播
  linkPlay:`api/bbs/link/play`,// 下载与查看回调
  liveStatistics:`api/bbs/link/duration`,// 直播视频统计
  getPsign: `api/tc/psign`,// 回放视频签名
  getinvitecode:`api/user/teach/team/invitecode`,// 获取小组邀请码
  codeMember:`api/user/teach/team/invite/member`,// 邀请码加入小组
  delGroupMember:`api/user/teach/del/team`, // 解散小组或删除某个成员
  myEnc: `api/edu/my/encyclopedias`, // 获取我的ar资源
  arEnc: `api/edu/ar/encyclopedia`, // 创建ar资源
  knowledgepoint: `api/edu/knowledgepoint`, // 知识点
  sortKnowledgepoint: `api/edu/knowledgepoint/sort`, // 知识点排序
  category: `api/edu/baike/category`,  // 百科类型
  myFbxFile: `api/course/my/fbx/file`, // AR资源处选模型列表
  myCourseAr: `api/edu/my/course/ar` // 我的ar-课程内
}
